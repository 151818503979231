<template>
  <addField
    v-if="typeof type != 'undefined' && type != null"
    :path="path"
    :additionalAttributes="{
      updateTable: true,
      function: 'getInsertedFieldsUpdateSelect',
      updatedField: this.field,
      updatedDialogRef: updatedDialogRef,
    }"
    :dialogAttributes="fieldAttributes['addingDialogOptions']"
  >
    <v-icon>mdi-plus</v-icon>
  </addField>
</template>
<script>
import addField from "@/commonComponents/addField.vue";
export default {
  props: ["result", "fieldAttributes", "sentUrl", "field", "updatedDialogRef"],
  components: { addField },
  methods: {
    created() {
    //  console.log(this.result);
    },
  },
  computed: {
    type() {
      let type = this.result.valueLine.c_type;
      //console.log(type);
      return type;
    },
    path() {
      //console.log(this.$route.query);
      let c_subType = this.result.additional.c_subType;
      let p = this.result.additional.p;
      //console.log(this.result);
      let type = this.result.valueLine.c_type;
      let path =
        this.sentUrl +
        "&p=" +
        p +
        "&c_subType=" +
        c_subType +
        "&c_type=" +
        type;
      return path;
    },
  },
};
</script>